.purchase-page {
    .billing-interval-select, .payment-method-select {
        .select-entry {
            cursor: pointer;
            transition: border 0.3s ease;
        }

        .select-entry.active {
            border-color: #727cf5 !important;
        }
    }

    .total-number {
        font-size: 1.3em;
    }

}