.chatBox-left {
  background: #fef5e4;
  border-radius: 3px;
  display: inline-block;
  padding: 12px;
  position: relative;

  i {
    display: block;
    font-size: 12px;
    font-style: normal;
    font-weight: bold;
    position: relative;
  }
  p {
    margin: 0;
    padding-top: 3px;
  }
}
.chatBox-left:after {
  left: -11px;
  top: 0;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-top-color: #fef5e4;
  border-width: 6px;
  margin-right: -1px;
  border-right-color: #fef5e4;
}

.chatBox-right {
  background: #fef5e4;
  border-radius: 3px;
  display: inline-block;
  padding: 12px;
  position: relative;

  i {
    display: block;
    font-size: 12px;
    font-style: normal;
    font-weight: bold;
    position: relative;
  }
  p {
    margin: 0;
    padding-top: 3px;
  }
}
.chatBox-right:after {
  border-color: transparent;
  right: -10px;
  left: auto;
  top: 0;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-top-color: #fef5e4;
  border-width: 6px;
  margin-right: -1px;
  border-left-color: #fef5e4;
}
