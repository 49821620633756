body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Custom theme css */
.nav-tabs.nav-bordered li button {
  border: 0;
  padding: 0.625rem 1.25rem;
}
.nav-tabs.nav-bordered li button.active {
  border-bottom: 2px solid #727cf5;
}

.user-settings-page .password-input {
  max-width: 500px;
}
