.plan-card {
    box-shadow: 0 4px 14px 0 #ddd;
    position: relative;
    border-radius: 8px;
    text-align: center;

    .plan-card-body {
        padding-bottom: 60px;
    }

    .buy-plan-btn {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 43px;
        padding: 10px 0;
    }

    h3.title {
        margin-bottom: 20px;
    }

    p {
        margin: 5px;
        font-size: 1em;
    }
}
